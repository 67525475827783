<template>
  <div class="main-block airdrop-product">
    <div class="airdrop-product__network">
      <div class="token">
        <img
          :src="
              isBinance
                ? require('@/assets/img/binance/ic-pancake.svg')
                : require('@/assets/img/ic-uniswap.svg')
            "
        />
      </div>
    </div>
    <div class="airdrop-product__tokens">
      <div class="from token">
        <img :src="require(`@/assets/img/${pool.fromToken || 'milk'}-token.svg`)"/>
      </div>
      <div class="to token">
        <img :src="require(`@/assets/img/${pool.toToken || 'milk'}-token.svg`)"/>
      </div>
    </div>
    <h4 class="airdrop-product__title">
      {{ isBinance ? 'PancakeSwap' : 'Uniswap v2' }} <br/>
      <span v-html="name"></span>
    </h4>
    <div class="airdrop-product__info">
      <span>{{ $t('airdrop.receivable') }}</span>
      <strong>{{ amount | amount}} NFTS</strong>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['name', 'amount', 'pool'],
  name: 'AirdropCard',
  computed: {
    ...mapState({
      isBinance() {
        return this.chainId === 56 || this.chainId === 97;
      },
      chainId: (state) => state.User.chainId,
    }),
  },
};
</script>

<style scoped lang="scss">
.airdrop-product {
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;

  &__network {
    margin-top: 2.2rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.6rem;

    .token {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FCFDFD;
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 50%;
      box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25),
      2px 2px 20px rgba(212, 202, 205, 0.2),
      -10px -10px 15px #FFFFFF,
      10px 10px 20px rgba(181, 155, 155, 0.25),
      inset 2px 2px 6px #FFFFFF,
      inset -1px -1px 2px rgba(181, 155, 155, 0.2);

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 2.8rem;
    min-height: 12.2rem;
    text-align: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin: 2.4rem 0 2.6rem;
    align-items: center;

    span {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.03em;
      color: #7478A0;
      max-width: 40%;
    }

    strong {
      font-weight: bold;
      font-size: 1.8rem;
    }
  }

  &__tokens {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;

    .token {
      margin-right: 0.4rem;
      margin-left: 0.4rem;
      background: #F8FCFD;
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25),
      2px 2px 20px rgba(212, 202, 205, 0.2),
      -10px -10px 15px #FFFFFF,
      10px 10px 20px rgba(181, 155, 155, 0.25),
      inset 2px 2px 6px #FFFFFF,
      inset -1px -1px 2px rgba(181, 155, 155, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 6.8rem;
        height: 6.8rem;
      }
    }
  }
}

body.binance {
  .airdrop-product {
    &__network {
      .token {
        background: #1C1C23;
        box-shadow: 4px 4px 8px #16161B,
        -2px -2px 4px rgba(69, 69, 75, 0.3),
        inset 0px 0px 3px rgba(25, 25, 31, 0.2),
        inset 0px 0px 4px rgba(44, 44, 52, 0.2),
        inset -1px -1px 6px #0E0E11,
        inset 1px 1px 2px rgba(118, 118, 148, 0.2);
      }
    }

    &__tokens {
      .token {
        background: #2F2F2F;
        box-shadow: 6px 6px 8px #16161B, -6px -6px 12px #333342,
        inset 2px 2px 5px #47475A,
        inset -1px -1px 4px rgba(0, 0, 0, 0.69),
        inset -3px -3px 12px rgba(4, 4, 10, 0.6),
        inset 2px 2px 2px rgba(255, 255, 255, 0.3);
      }
    }
  }
}
</style>
