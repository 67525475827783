<template>
  <div class="page-content">
    <div class="main-wrap main-wrap--narrow">
      <h1 class="page-title">{{ $t('airdrop.title') }}</h1>

      <v-row justify="center">
        <v-col md="4" sm="4" xs="12" class="airdrop-col" v-if="stage !== 'CLAIM'">
          <div class="main-block airdrop-info">
            <span v-if="stage === 'STARTED'">{{ $t('airdrop.drop_snapshot') }}</span>
            <span v-else> {{ $t('airdrop.nft_claim') }}</span>

            <strong>
              {{ countdown.d }}d {{ countdown.h }}h {{ countdown.m }}m {{ countdown.s }}s
            </strong>
          </div>
        </v-col>

        <v-col md="4" sm="4" xs="12" class="airdrop-col" v-else>
          <div class="main-block airdrop-info">
            <span>Total amount</span>
            <strong>{{ harvest_amount | amount }} NFTS</strong>
            <Button :disabled="loader || !harvest_amount" class="nft-link" @click="harvest">
              {{ !loader ? 'CLAIM' : 'Loading ...' }}</Button
            >
          </div>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="4" sm="4" xs="12" v-for="(pool, index) in pools" :key="index">
          <AirdropCard
            :pool="pool"
            :amount="(stage !== 'CLAIM' ? pool.amountPending : pool.amountAvailable) || `...`"
            :name="pool.symbol"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import AirdropCard from '@/components/AirdropCard';
import Button from '@/components/Button';
import { getDrops, forceUpdate } from '@/helpers/api/shadow.api';
import { getShadowPools } from '@/constants/shadowPools';
import { addNotification } from '@/utils/notification';

dayjs.extend(utc);

export default {
  name: 'Airdrop',
  components: { Button, AirdropCard },
  data() {
    return {
      drop: {},
      stage: 'STARTED',
      stageDate: null,
      pools: [],
      isInit: false,
      loader: false,
      now: {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
      },
      harvest_amount: 0,
    };
  },

  computed: {
    ...mapState({
      isBinance() {
        return this.chainId === 56 || this.chainId === 97;
      },
      ethAddress: (state) => state.User.ethAddress,
      chainId: (state) => state.User.chainId,
    }),
    countdown() {
      const { now } = this;
      const diff = this.stageDate - now > 0 ? this.stageDate - now : 0;
      const days = Math.floor(diff / 1000 / 3600 / 24);
      const hours = Math.floor((diff / 1000 / 3600) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor((diff / 1000) % 60);
      this.checkStage();
      return {
        d: days,
        h: hours,
        m: minutes,
        s: seconds,
      };
    },
  },
  watch: {
    stage() {
      if (this.isInit) {
        this.init();
      }
    },
  },
  async mounted() {
    await this.init();
    this.isInit = true;
    window.setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  methods: {
    async init() {
      const pools = getShadowPools('interstellar');
      this.pools = [];
      try {
        const response = await getDrops(this.ethAddress);
        this.drop = response.drops.find((drop) => drop.active);
        this.harvest_amount = this.$contracts.fromWei(response.harvest.amount);

        Object.entries(pools).forEach((pool) => {
          const amounts = this.drop.atPoolsInformation.find(
            (p) => p.contract === pool[1].lpContract,
          );
          if (amounts) {
            this.pools.push({
              ...pool[1],
              amountAvailable: this.$contracts.fromWei(amounts.amountAvailable),
              amountPending: this.$contracts.fromWei(amounts.amountPending),
            });
          }
        });
      } catch (e) {
        this.$router.push('/');
      }
    },
    async harvest() {
      this.loader = true;
      const drops = await getDrops(this.ethAddress);
      try {
        this.$contracts
          .getContract('air')
          .methods.harvest(
            drops.harvest.amount,
            drops.harvest.lastBlockNumber,
            drops.harvest.currentBlockNumber,
            drops.harvest.msgForSign,
            drops.harvest.signature,
          )
          .send({ from: this.ethAddress })
          .once('transactionHash', (hash) => {
            addNotification(
              {
                type: 'info',
                title: 'Pending claim:',
                data: { hash },
              },
              { root: true },
            );
          })
          .once('confirmation', () => {
            addNotification(
              {
                type: 'success',
                title: 'Successful claim!',
                config: { duration: 5000 },
              },
              { root: true },
            );
            this.harvest_amount = 0;
            forceUpdate(this.ethAddress).then(() => {
              this.loader = false;
            });
          })
          .once('error', () => {
            addNotification(
              {
                type: 'warn',
                title: 'Claim failed',
                config: { duration: 5000 },
              },
              { root: true },
            );
            this.loader = false;
          });
      } catch (e) {
        this.loader = false;
        console.error(e);
      }
    },
    checkStage() {
      const currentTime = dayjs().unix();
      const snapshotAt = dayjs(this.drop.snapshotAt).unix();
      const rewardsAt = dayjs(this.drop.rewardsAt).unix();

      if (currentTime > rewardsAt) {
        this.stage = 'CLAIM';
      } else if (currentTime > snapshotAt) {
        this.stage = 'SNAPSHOT';
        this.stageDate = new Date(dayjs(this.drop.rewardsAt).unix() * 1000);
      } else {
        this.stageDate = new Date(dayjs(this.drop.snapshotAt).unix() * 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.airdrop-col {
  min-width: 30rem;
}

.airdrop-info {
  padding: 2.8rem 3.2rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: center;

  span {
    margin-bottom: 1.4rem;
    font-weight: 600;
    font-size: 1.6rem;
  }

  strong {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 2.7rem;
  }
}

body.binance {
  .airdrop-info {
    span {
      color: #a7aeba;
    }
  }
}

.nft-link {
  min-width: 26rem;
  margin: 3.4rem auto 0.5rem auto;
}
</style>
